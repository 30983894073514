import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export type lazyLoadingState = boolean;

const initialState = false;
const lazyLoading = createSlice({
    name: 'LazyLoading',
    initialState,
    reducers: {
        setLazyLoading: (_state, action: PayloadAction<lazyLoadingState>) =>
            action.payload,
    },
});

export const lazyLoadingActions = lazyLoading.actions;
export default lazyLoading.reducer;

export const lazyLoadingSelector = (state: RootState) =>
    state.application.lazyLoading;
