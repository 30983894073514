import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiCatalogReferencesResponse } from '@api/types/catalog';
import { RootState } from '..';

export type ReferencesState = ApiCatalogReferencesResponse;

const initialState: ReferencesState = { color: [], sex: [], size: [] };
const references = createSlice({
    name: 'References',
    initialState,
    reducers: {
        setReferences: (
            _state: ReferencesState,
            action: PayloadAction<ReferencesState>
        ) => action.payload,
    },
});

export const referencesActions = references.actions;
export default references.reducer;

export const referencesSelector = (state: RootState) =>
    state.application.references;
