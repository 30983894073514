import { RootState, AppThunk, AppThunkAction } from '.';
import { ApiCatalogItemsResponse } from '@api/types/catalog';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import { KoaAppState } from 'index';
import { loadingActions } from './application/loading';
import { applicationSelector } from './application';
import { shopSettingsSelector } from './application/shopSettings';
import { catalogDuck } from '@vsemayki/shared-frontend';
import { lazyLoadingActions } from './application/lazyLoading';
import { contextSelector } from '@ducks/application/context';

const { apiRequest } = ApiMiddleware;
export const {
    catalogActions,
    catalogReducer,
    pathMainCatalogItems,
} = catalogDuck;

export type CatalogState = catalogDuck.CatalogState;

export default catalogReducer;

export const catalogSelector = (state: RootState) => state.catalog;
export const catalogOptionsSelector = (state: RootState) =>
    state.catalog.options;

export const getCatalogIndexItems: () => AppThunk<Promise<void>> = () => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const { references } = applicationSelector(State);

        dispatch(loadingActions.setLoading(true));
        dispatch(catalogActions.setLoading(true));

        const Response = await dispatch<ApiCatalogItemsResponse>(
            apiRequest({
                url: 'catalogue/index/items',
            })
        );
        const { items } = Response;
        const mappedItems = pathMainCatalogItems(
            references ?? { color: [], sex: [], size: [] },
            false
        )(items);

        // if (items.length === 0) {
        //     dispatch(setNotFound());
        // }

        dispatch(catalogActions.setItems(mappedItems));

        dispatch(loadingActions.setLoading(false));
        dispatch(catalogActions.setLoading(false));
    } catch (error) {
        dispatch(loadingActions.setLoading(false));
        console.error(error);
    }
};

export const getCatalogItems: (
    context: KoaAppState['context'],
    specialType?: string
) => AppThunk<Promise<void>> = (context, specialType) => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const { references } = applicationSelector(State);
        const ShopState = shopSettingsSelector(State);
        const ContextState = contextSelector(State);
        const TagsState = State.application.tags;
        const StructureState = State.application.structure;

        dispatch(catalogActions.setLoading(true));

        const {
            query = { sort: '', page: 1 },
            filter = { value: '' },
            custom = {},
            collection = { category: '', subCategory: '' },
        } = context.getContextData();

        const search = custom.search_page || custom.tag;

        const sort = query.sort
            ? query.sort
            : collection.category
            ? undefined
            : 'sell';
        const limitCount = specialType ? 8 : 48;
        const querySort = specialType ? 0 : (query.page ?? 1) - 1;

        const categoryTag =
            ContextState.collection &&
            TagsState.find((el) => el.id === ContextState.collection?.category);
        const subCategoryTag =
            ContextState.collection &&
            TagsState.find(
                (el) =>
                    el.id ===
                    `${ContextState.collection?.category}__${ContextState.collection?.subCategory}`
            );
        const structureTag =
            ContextState.filter?.value &&
            StructureState.find(
                (struct) => struct.id === ContextState.filter?.value
            );
        let filterTag;
        let filterCategory;

        switch (specialType) {
            case 'category':
                filterTag = '';
                if (structureTag) {
                    filterCategory = filter.value;
                }
                break;
            case 'collection':
                filterCategory = '';
                filterTag = '';
                if (categoryTag) {
                    filterTag += collection.category;
                }
                if (subCategoryTag) {
                    filterTag += `__${collection.subCategory}`;
                }
                break;
            default:
                filterTag = context.getCategoryAlias();
                filterCategory = filter.value;
                break;
        }

        await dispatch(
            catalogDuck.getCatalogItems({
                references,
                reqParams: {
                    'filter[tag]': filterTag,
                    'filter[category]': filterCategory,
                    'filter[search]': search
                        ? decodeURIComponent(search)
                        : undefined,
                    'filter[force_partner]': [1099463, 93073].includes(
                        ShopState.id || 0
                    )
                        ? 1
                        : undefined,
                    limit: limitCount,
                    offset: limitCount * querySort,
                    sort: sort,
                },
                specialType,
            })
        );

        dispatch(catalogActions.setLoading(false));
    } catch (error) {
        if (specialType === 'collection') {
            dispatch(catalogActions.setCollectionItems([]));
        }
        if (specialType === 'category') {
            dispatch(catalogActions.setCategoryItems([]));
        }
        dispatch(catalogActions.setItems([]));
        dispatch(catalogActions.setLoading(false));

        console.error('getCatalogItems', error);
    }
};

export const getCatalogItemsLazy: AppThunkAction<KoaAppState['context']> = (
    context
) => async (dispatch, getState) => {
    try {
        const State = getState();
        const { references } = applicationSelector(State);
        const CatalogState = catalogSelector(State);
        const ShopState = shopSettingsSelector(State);

        //TODO: refactor it to be in scope of catalog state
        dispatch(lazyLoadingActions.setLazyLoading(true));

        const {
            query = { sort: '', page: 1 },
            filter = { value: '' },
            custom = {},
            collection = { category: '' },
        } = context.getContextData();

        const search = custom.search_page || custom.tag;

        const sort = query.sort
            ? query.sort
            : collection.category
            ? undefined
            : 'sell';

        const Response = await dispatch<ApiCatalogItemsResponse>(
            apiRequest({
                url: 'rest/catalog/items',
                params: {
                    'filter[tag]': context.getCategoryAlias(),
                    'filter[category]': filter.value,
                    'filter[search]': search
                        ? decodeURIComponent(search)
                        : undefined,
                    'filter[force_partner]': [1099463, 93073].includes(
                        ShopState.id || 0
                    )
                        ? 1
                        : undefined,
                    limit: CatalogState.options.limit,
                    offset:
                        CatalogState.options.limit * ((query.page ?? 1) - 1),
                    sort: sort,
                },
            })
        );

        const { items, total_items, limit, offset } = Response;
        const mappedItems = pathMainCatalogItems(
            references ?? { color: [], sex: [], size: [] },
            false
        )(items);

        dispatch(
            catalogActions.setItems([...CatalogState.items, ...mappedItems])
        );

        dispatch(catalogActions.setOptions({ total_items, limit, offset }));
        dispatch(lazyLoadingActions.setLazyLoading(false));
    } catch (error) {
        dispatch(catalogActions.setItems([]));
        dispatch(lazyLoadingActions.setLazyLoading(false));

        console.error('catalogDucl::getCatalogItemsLazy', error);
    }
};
