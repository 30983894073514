import { combineReducers } from '@reduxjs/toolkit';

import { AppThunk, RootState } from '@store/ducks/index';
import {
    ApiCatalogReferencesResponse,
    ApiCatalogStructureResponse,
    ApiCatalogTagsResponse,
} from '@api/types/catalog';
import { ApiMiddleware } from '@vsemayki/shared-frontend';

// reducers
import context from './context';
import loading from './loading';
import lazyLoading from './lazyLoading';
import references, { referencesActions } from './references';
import structure, {
    structureActions,
    setTypesStructureElements,
} from './structure';
import tags, { tagsActions } from './tags';
import menu from './menu';
import breadcrumbs from './breadcrumbs';
import banners from './banners';
import shopSettings from './shopSettings';
import user from './user';
import domain from './domain';

export default combineReducers({
    context,
    loading,
    lazyLoading,
    references,
    structure,
    tags,
    menu,
    breadcrumbs,
    banners,
    shopSettings,
    user,
    domain,
});

const { apiRequest } = ApiMiddleware;
export const applicationSelector = (state: RootState) => state.application;

export const fetchBase = (): AppThunk<Promise<void>> => async (
    dispatch,
    getState
) => {
    try {
        const { tags, references, structure } = getState().application;
        const promises: Promise<{
            payload:
                | ApiCatalogTagsResponse
                | ApiCatalogReferencesResponse
                | ApiCatalogStructureResponse;
            type: string;
        }>[] = [];
        if (!tags.length) {
            promises.push(
                dispatch<ApiCatalogTagsResponse>(
                    apiRequest({ url: `/rest/catalog/tags` })
                ).then((tags) => dispatch(tagsActions.setTags(tags)))
            );
        }

        if (
            !references.color.length ||
            !references.sex.length ||
            !references.size.length
        ) {
            promises.push(
                dispatch<ApiCatalogReferencesResponse>(
                    apiRequest({
                        url: `/rest/catalog/references`,
                    })
                ).then((refs) =>
                    dispatch(referencesActions.setReferences(refs))
                )
            );
        }

        if (!structure.length) {
            promises.push(
                dispatch<ApiCatalogStructureResponse>(
                    apiRequest({
                        url: `/rest/catalog/structure`,
                    })
                ).then((structure) =>
                    dispatch(
                        structureActions.setStructure(
                            setTypesStructureElements(structure)
                        )
                    )
                )
            );
        }
        await Promise.all(promises);
    } catch (error) {
        console.error(error);
    }
};
