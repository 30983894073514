import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '..';
import { APIMenuResponse } from '@api/types/menu';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
const { apiRequest } = ApiMiddleware;

export type MenuKeys =
    | 'top'
    | 'mobile'
    | 'bottom'
    | 'header_new_main'
    | 'middle_header_menu'
    | 'left_menu'
    | 'header_partners';
export type MenuStateItem = APIMenuResponse['items'][0];
export type MenuState = Record<MenuKeys, MenuStateItem[]>;

const initialState: MenuState = {
    bottom: [],
    header_new_main: [],
    left_menu: [],
    middle_header_menu: [],
    mobile: [],
    top: [],
    header_partners: [],
};

const menu = createSlice({
    name: 'Menu',
    initialState,
    reducers: {
        setMenuKey: (
            state,
            action: PayloadAction<{ key: MenuKeys; response: APIMenuResponse }>
        ) => {
            const { key, response } = action.payload;
            state[key] = response.items;
        },
    },
});
export const menuActions = menu.actions;
export default menu.reducer;

export const menuSelector = (state: RootState) => state.application.menu;
export const menuSpecificSelector = (key: MenuKeys) => (state: RootState) =>
    menuSelector(state)[key];

export const getMenu = (key: MenuKeys): AppThunk<Promise<void>> => async (
    dispatch,
    getState
) => {
    const State = getState();
    const MenuState = menuSelector(State);

    //if menu key not empty skip it
    if (MenuState[key].length) {
        return;
    }

    try {
        const response = await dispatch<APIMenuResponse>(
            apiRequest({ url: `/menu/${key}` })
        );

        if (!Array.isArray(response.items)) {
            throw new Error(
                `Response is not array, response ${typeof response}, JSON: ${JSON.stringify(
                    response
                )}`
            );
        }

        dispatch(menuActions.setMenuKey({ key, response }));
    } catch (error) {
        console.error(error);
    }
};
