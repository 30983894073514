import dayjs from 'dayjs';
import pickBy from 'lodash/pickBy';
import { resolve, CONTEXT } from '@vsemayki/url-resolver';
import { AppThunkAction, RootState } from '..';
import { LiteShopContext, KoaAppState } from 'index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import { APIBannersResponse, APIBannersResponseItem } from '@api/types/banners';
import { geoSelector } from '@ducks/geo';
import { shopSettingsSelector } from './shopSettings';

const { apiRequest } = ApiMiddleware;

export type BannersKeys = 'top';
export type BannersItem = APIBannersResponseItem & { context: LiteShopContext };

export type BannersState = Record<BannersKeys, BannersItem[]> & {
    loading: boolean;
};

const initialState: BannersState = { top: [], loading: true };

const banners = createSlice({
    name: 'Banners',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<BannersState['loading']>) => {
            state.loading = action.payload;
        },
        setBanners: (
            state,
            action: PayloadAction<{
                placement: BannersKeys;
                items: BannersState[BannersKeys];
            }>
        ) => {
            state[action.payload.placement] = action.payload.items;
        },
    },
});

export const bannersActions = banners.actions;

export default banners.reducer;

export const bannersSelector = (state: RootState) => state.application.banners;

type getBannersProps = {
    placement: BannersKeys;
    context: KoaAppState['context'];
};

export const getBanners: AppThunkAction<getBannersProps> = ({
    placement,
    context,
}) => async (dispatch, getState) => {
    const State = getState();
    const GeoState = geoSelector(State);
    const ShopSettingsState = shopSettingsSelector(State);

    const { name } = context.getContextData();
    const { region } = GeoState;
    const category = context.getCategory();
    const contextName =
        name === 'index' ? 'main' : category ? 'category' : 'all';

    const options = {
        shop_id: ShopSettingsState.id,
        placement: { alias: placement },
        platform: ['mobile', 'desktop', 'all'],
        client_time: dayjs().startOf('hour').format('YYYY-MM-DD HH:mm:ss Z'),
        context: {
            alias: contextName,
            attributes: pickBy(
                {
                    category,
                    subcategory: context.getSubCategory(),
                },
                (v) => v !== undefined
            ),
        },
        region,
    };

    dispatch(bannersActions.setLoading(true));

    try {
        const banners = await dispatch<APIBannersResponse>(
            apiRequest({
                url: '/rest/proxy-banner/shop/banners',
                method: 'POST',
                data: options,
            })
        ).then((response) => response.sort((a, b) => a.priority - b.priority));

        const newBanners = banners.map<BannersItem>((banner) => {
            try {
                const context = resolve(banner.banner.href).getContextData();
                return { ...banner, context };
            } catch (e) {
                console.error(
                    `[BANNER:ERROR] fail parse ${banner.banner.href}`,
                    e.toString()
                );
                return { ...banner, context: { name: CONTEXT.index } };
            }
        });

        dispatch(
            bannersActions.setBanners({
                placement: placement,
                items: newBanners,
            })
        );
    } catch (e) {
        console.error(e);
        dispatch(bannersActions.setLoading(false));
    }
};
